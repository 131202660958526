<template>
  <tr class="bg-white hover:bg-gray-100 cursor-pointer">
    <td class="px-1 py-1 text-left pl-2">
      <input type="checkbox" class="form-checkbox mt-1 h-4 w-4 text-gray-600" />
    </td>
    <td class="px-1 py-2 text-left">{{ catalogue.name }}</td>
    <td class="px-1 py-2 text-left">{{ catalogue.brand.name }}</td>
    <td class="px-1 py-2 flex items-center">
      <div
        @click="showDropDown = false"
        v-if="showDropDown"
        class="dropDownOverlay"
      ></div>

      <button @click="showDropDown = !showDropDown" class="relative">
        <svg
          class="h-5 w-5 fill-current text-grey-dark"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M4 12a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm6 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
          />
        </svg>

        <div v-if="showDropDown" class="dropdown absolute top-0 right-0 z-50">
          <ul class="w-full bg-white shadow rounded text-xl">
            <li
              @click="deleteConfirmVisible = true"
              class="py-2 px-4 text-sm hover:bg-gray-100"
            >
              Delete
            </li>
          </ul>
        </div>
      </button>
      <!-- Edit Customer Modal -->
      <!-- <EditCustomerModal
        @customerEdited="editCustomer"
        @overlayClicked="editCustomerVisible = false"
        :customer="customer"
        :isVisible="editCustomerVisible"
      ></EditCustomerModal> -->

      <!-- DELETE CONFIRM MODAL -->
      <div
        v-if="deleteConfirmVisible"
        @click="deleteConfirmVisible = false"
        class="confirm-overlay"
      ></div>
      <div
        v-if="deleteConfirmVisible"
        class="delete-confirm text-xl shadow rounded p-10"
      >
        <div>
          Delete
          <span class="font-bold">{{ catalogue.name }}</span> from catalogues?
          <br />
          <span class="inline-block my-4 text-red-700"
            >This action cannot be undone.</span
          >
        </div>
        <div class="flex justify-end mt-6">
          <button
            @click="deleteConfirmVisible = false"
            class="py-2 px-4 bg-green-600 text-white rounded mr-2"
          >
            No
          </button>
          <button
            @click="
              (deleteConfirmVisible = false), deleteCatalogue(catalogue._id)
            "
            class="py-2 px-4 bg-red-600 text-white rounded"
          >
            Yes
          </button>
        </div>
      </div>
      <!-- / DELETE CONFIRM MODAL -->
    </td>
  </tr>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "CatalogueTableItem",
  props: ["catalogue"],
  data() {
    return {
      deleteConfirmVisible: false,
      showDropDown: false,
    };
  },
  methods: {
    ...mapActions(["deleteCatalogue"]),
  },
};
</script>

<style scoped>
.dropDownOverlay {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 49;
}

.delete-confirm {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
}

.confirm-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9998;
}
</style>